import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField } from '../../components';
import APIClient from '../../services/APIClient';
import { WithRouterProps } from '../withRouter';
import { AxiosResponse } from 'axios';

interface ProductCategory2EditState {
  id: number;
  isAdding: boolean;
  entity: any;
}

class ProductCategory2Edit extends React.Component<WithRouterProps, ProductCategory2EditState> {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get linea
    const producCategory2Res = await APIClient.get(`/product-categories-2/${id}`);
    const entity = producCategory2Res.data.data;

    this.setState({ entity });
    return entity;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { navigate, toastManager } = this.props;

    let saveResponse: AxiosResponse;
    if (isAdding) {
      saveResponse = await APIClient.post('/product-categories-2', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/product-categories-2/${id}`, entityToSave);
    }

    toastManager.add(
      `Categoría 2 ${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => navigate('/product-categories-2'),
    );
    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Nueva Categoría 2' : `Categoría 2 #${id}`}</h1>

        <EntityEditForm
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Row>
              <Col>
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  as="input"
                  defaultValue={entity && entity.descripcion}
                  required
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ProductCategory2Edit);
