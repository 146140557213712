import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { AxiosError, AxiosResponse } from 'axios';
import { EntityEditForm, FormInputField, FormCheckField, FormSelectField } from '../../components';
import APIClient from '../../services/APIClient';
import { WithRouterProps, withRouter } from '../withRouter';
import FormSelectCustom, { SelectCustomOption } from '../../components/FormSelectCustom';
import { compareUserRoles } from '../Utils';

interface UserEditProps {
  id: number;
  isAdding: boolean;
  // entity defaults
  entity: any;
  vendedores: any[];
  roles: any[];
  selectedRoles: any[];
  unselectedRoles: any[];
}

class UsuarioEdit extends React.Component<WithRouterProps, UserEditProps> {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      // entity defaults
      entity: {
        isActive: true,
        perfil: {},
      },
      vendedores: [],
      roles: [],
      selectedRoles: [],
      unselectedRoles: [],
    };
  }

  onLoadForm = async () => {
    // get vendedores
    const vendedoresResponse = await APIClient.get('/vendedores');
    // get roles
    const roles = await APIClient.get('/role');

    this.setState({
      vendedores: vendedoresResponse.data.data,
      roles: roles.data.data,
    });
  };

  selectCustomLoadOptionsHandler = async (query: string): Promise<SelectCustomOption[]> => {
    const res = await APIClient.get(
      `/clientes?filter[razonSocial][like]=${encodeURIComponent(`%${query}%`)}`,
    );
    const dataFetched = res.data.data;
    const optionsFormatted = dataFetched.map((data: any) => ({
      value: data.id,
      label: data.razonSocial,
    }));
    return optionsFormatted;
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get usuario
    const usuarioRes = await APIClient.get(`/usuarios/${id}`);
    const entity = usuarioRes.data.data;
    this.setState({ entity });
    return entity;
  };

  handlerRolesChecks = (e, rol) => {
    const { selectedRoles, unselectedRoles, entity } = this.state;
    const { checked } = e.target;

    const existRol = entity.roles ? entity.roles.some((userRol) => userRol.id === rol.id) : false;
    let newRoles = selectedRoles;
    let newUnselectedRoles = unselectedRoles;

    if (checked) {
      if (!existRol) {
        newRoles.push(rol);
      }

      newUnselectedRoles = newUnselectedRoles.filter((e) => e.id !== rol.id);
    } else {
      if (existRol) {
        newUnselectedRoles.push(rol);
      }

      newRoles = newRoles.filter((e) => e.id !== rol.id);
    }

    this.setState({
      selectedRoles: newRoles,
      unselectedRoles: newUnselectedRoles,
    });
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, selectedRoles, unselectedRoles } = this.state;
    const { navigate, toastManager } = this.props;
    try {
      entityToSave.roles = selectedRoles;
      entityToSave.rolesToUpdate = unselectedRoles;
      let saveResponse: AxiosResponse;
      if (isAdding) {
        saveResponse = await APIClient.post('/usuarios', entityToSave);
      } else {
        const rolesToUpdate = {
          rolesToRemove: unselectedRoles,
          rolesToAdd: selectedRoles,
        };
        await APIClient.patch(`/usuarios/roles?userId=${id}`, rolesToUpdate);
        saveResponse = await APIClient.patch(`/usuarios/${id}`, entityToSave);
      }

      navigate('/usuarios');
      if (isAdding) {
        toastManager.add(
          <>
            <p>{`Usuario ${saveResponse.data.data.id} guardado con éxito`}</p>
            <p>
              Se ha generado automáticamente la contraseña:
              <br />
              <strong>{saveResponse.data.data.newPassword}</strong>
            </p>
          </>,
          {
            appearance: 'success',
          },
        );
      } else {
        toastManager.add(`Usuario ${saveResponse.data.data.id} guardado con éxito`, {
          appearance: 'success',
          autoDismiss: true,
        });
      }
      return saveResponse.data.data;
    } catch (error) {
      let errorMessage = (error as Error)?.message;
      const axiosError = error as AxiosError;
      if (axiosError && axiosError.response && axiosError.response.data.message) {
        errorMessage = axiosError.response.data.message;
      }
      toastManager.add(`Error al momento de guardar el usuario: ${errorMessage}`, {
        appearance: 'error',
      });
    }
  };

  render() {
    const { id, entity, isAdding, vendedores, roles } = this.state;
    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Usuario nuevo' : `Usuario #${id}`}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="firstName"
                  label="Nombre"
                  as="input"
                  defaultValue={entity.firstName}
                  required
                />
                <FormInputField
                  id="lastName"
                  label="Apellido"
                  as="input"
                  defaultValue={entity.lastName}
                  required
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="username"
                  label="Username (login)"
                  as="input"
                  defaultValue={entity.username}
                  required
                />
                <FormInputField
                  id="emailAddress"
                  label="E-mail"
                  as="input"
                  type="email"
                  defaultValue={entity.emailAddress}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectCustom
                  id="perfil.clienteId"
                  label="Cliente"
                  selectedOption={{
                    value: entity.perfil.clienteId,
                    label: entity.perfil?.Cliente?.razonSocial,
                  }}
                  onLoadOptions={this.selectCustomLoadOptionsHandler}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="perfil.vendedorId"
                  label="Ejecutivo comercial"
                  defaultValue={entity.perfil.vendedorId}
                  choices={vendedores}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-2">
                <FormCheckField id="isActive" label="Activo" defaultChecked={entity.isActive} />
              </Col>
              <Col md={12} className="mb-3">
                <Card>
                  <Card.Header>Permisos de usuario</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <Card>
                          <Card.Header>Permisos disponibles</Card.Header>
                          {roles.map((role) => (
                            <Card.Body key={role.id}>
                              <FormCheckField
                                id={`rol_${role.id}`}
                                label={role.descripcion}
                                onChange={(e) => this.handlerRolesChecks(e, role)}
                                defaultChecked={
                                  entity.roles ? compareUserRoles(entity.roles, role) : false
                                }
                              />
                            </Card.Body>
                          ))}
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card>
                          <Card.Header>Permisos administrativos</Card.Header>
                          <Card.Body>
                            <FormCheckField
                              id="isSuperAdmin"
                              label="Super Adminsitrador"
                              defaultChecked={entity.isSuperAdmin}
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(withRouter(UsuarioEdit));
