import React from 'react';
import PropTypes from 'prop-types';
import { AxiosResponse } from 'axios';
import { Row, Col, Card } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import {
  EntityEditForm,
  FormAddressInput,
  FormInputField,
  FormCheckField,
  FormSelectField,
} from '../../components';
import { checkDayData } from './utils';
import APIClient from '../../services/APIClient';
import { WithRouterProps, withRouter } from '../withRouter';

interface DeliveryPointEditProps {
  id: number;
  entity: any;
  clientes: any[];
  expresos: any[];
  isAdding: boolean;
  provincias: any[];
  checkedDays: any[];
  days: any[];
}

class PuntoEntregaEdit extends React.Component<WithRouterProps, DeliveryPointEditProps> {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: WithRouterProps) {
    super(props);

    const { id } = props.params;

    this.state = {
      clientes: [],
      entity: {},
      expresos: [],
      id,
      isAdding: typeof id === 'undefined',
      provincias: [],
      checkedDays: [],
      days: [],
    };
  }

  onLoadForm = async () => {
    // get provincias
    const provinciasRes = await APIClient.get('/provincias');

    // get clientes
    const clientesRes = await APIClient.get('/clientes?sortField=razonSocial&sortDir=asc');

    // get expresos
    const expresosRes = await APIClient.get('/expresos');

    this.setState({
      clientes: clientesRes.data.data,
      expresos: expresosRes.data.data,
      provincias: provinciasRes.data.data,
      days: checkDayData([]),
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get puntoEntrega
    const puntoEntregaRes = await APIClient.get(`/puntos-entrega/${id}`);
    let checkedDays;
    if (puntoEntregaRes.data.data.deliveryDays) {
      checkedDays = puntoEntregaRes.data.data.deliveryDays.split('');
    }
    this.setState({
      entity: puntoEntregaRes.data.data,
      checkedDays,
      days: checkedDays ? checkDayData(checkedDays) : checkDayData([]),
    });

    return puntoEntregaRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, entity, checkedDays } = this.state;
    const { navigate, toastManager } = this.props;

    let saveResponse: AxiosResponse;
    entityToSave.deliveryDays = JSON.stringify(checkedDays);
    if (isAdding) {
      saveResponse = await APIClient.post('/puntos-entrega', entityToSave);
    } else {
      if (entityToSave.diasEntrega || entityToSave.entregaHorario) {
        await APIClient.patch(`/clientes/${entity.clienteId}`, entityToSave);
      }
      saveResponse = await APIClient.patch(`/puntos-entrega/${id}`, entityToSave);
    }

    navigate && navigate('/puntos-entrega');
    toastManager.add(`Punto de Entrega ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  onCheckedDay = (event) => {
    const { id, checked } = event.target;
    const { checkedDays } = this.state;
    let newCheckedDays = checkedDays;

    if (checked) {
      newCheckedDays.push(id);
      this.setState({
        ...this.state,
        checkedDays: newCheckedDays,
      });
    } else {
      newCheckedDays = newCheckedDays.filter((element) => element !== id);
      this.setState({
        ...this.state,
        checkedDays: newCheckedDays,
      });
    }
  };

  render() {
    const { clientes, entity, expresos, id, isAdding, provincias, days } = this.state;

    return (
      <div>
        <h1 className="page-title">
          {isAdding ? 'Punto de Entrega nuevo' : `Punto de Entrega #${id}`}
        </h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  as="input"
                  defaultValue={entity.descripcion}
                />
              </Col>
            </Row>

            <FormAddressInput addMode={isAdding} entity={entity} provincias={provincias} />

            <Row>
              <Col md={6}>
                <FormInputField id="email" label="Email" as="input" defaultValue={entity.email} />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="telefono"
                  label="Teléfono"
                  as="input"
                  defaultValue={entity.telefono}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="clienteId"
                  label="Cliente"
                  choices={clientes}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                  defaultValue={entity.clienteId}
                  placeholder="(Sin Cliente asociado)"
                  disabled={true}
                />
              </Col>
              {/* <Col md={6}>
                <FormSelectField
                  id="expresoId"
                  label="Expreso"
                  type="text"
                  choices={expresos}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  defaultValue={entity.expresoId}
                  placeholder="(Sin Expreso)"
                  disabled={true}
                />
              </Col> */}
            </Row>
            <Row>
              <Col md={6}>
                <Card>
                  <Card.Header>Horarios de entrega</Card.Header>
                  <Card.Body>
                    <Row>
                      {days.map((day) => (
                        <Col key={day.key} md={2}>
                          <FormCheckField
                            id={day.key}
                            label={day.label}
                            defaultChecked={day.checked}
                            onChange={(e) => this.onCheckedDay(e)}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormInputField
                          id="deliveryTime"
                          label="Horario"
                          as="input"
                          defaultValue={entity.deliveryTime}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormCheckField
                  id="isEliminado"
                  label="Deshabilitado"
                  defaultChecked={entity.isEliminado}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(withRouter(PuntoEntregaEdit));
