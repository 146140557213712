import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { EntityEditForm, FormInputField, FormCheckField, FormSelectField } from '../../components';
import APIClient from '../../services/APIClient';
import { AxiosResponse } from 'axios';
import { Company } from '../../types/model';
import { useToasts } from 'react-toast-notifications';
import { countries } from '../../countries';

export default function CompanyEdit() {
  const [isNewConfig, setIsNewConfig] = useState(false);
  const [entity, setEntity] = useState<Partial<Company> | undefined>(undefined);

  const { addToast } = useToasts();

  const onLoadForm = async () => {
    const companiesRes = await APIClient.get(`/companies`);
    // For now, we are only expecting a single company.
    setIsNewConfig(companiesRes.data.data.length === 0);
    setEntity(companiesRes.data.data[0]);
  };

  const onSaveEntity = async (entityToSave: Company) => {
    let saveResponse: AxiosResponse;
    if (isNewConfig) {
      saveResponse = await APIClient.post('/companies', entityToSave);
      setIsNewConfig(false);
      setEntity(saveResponse.data.data);
    } else {
      saveResponse = await APIClient.patch(`/companies/${entity?.id}`, entityToSave);
    }
    addToast(`Compañía guardada con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onRetrieveEntity = () => {};

  return (
    <div>
      <Row className="page-title">
        <Col className="d-flex justify-content-between pe-0" md={12}>
          <h1 className="d-flex my-3">Configuración de Compañía</h1>
        </Col>
      </Row>

      <EntityEditForm
        onLoadForm={onLoadForm}
        onSaveEntity={onSaveEntity}
        onRetrieveEntity={onRetrieveEntity}
        addMode={isNewConfig}>
        <Card className="mb-2">
          <Card.Body>
            <Card.Title>Detalles de la Compañía</Card.Title>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="companyName"
                  label="Razón Social"
                  as="input"
                  defaultValue={entity?.companyName}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="countryCode"
                  label="País"
                  choices={countries}
                  defaultValue={entity?.countryCode}
                  choiceIdField={'code'}
                  choiceLabelField={'name'}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="tradeName"
                  label="Nombre de Fantasía"
                  as="input"
                  defaultValue={entity?.tradeName}
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="taxCode"
                  label="Código Fiscal"
                  as="input"
                  defaultValue={entity?.taxCode}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="sector"
                  label="Rubro"
                  as="input"
                  defaultValue={entity?.sector}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormCheckField
                  id="enabled"
                  label="Activa"
                  defaultChecked={entity?.enabled ?? true}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mb-2">
          <Card.Body>
            <Card.Title>Detalles de la Compañía</Card.Title>
            <Row>
              <Col md={12}>
                <FormInputField
                  id="briefDescription"
                  label="Misión/Visión"
                  as="textarea"
                  defaultValue={entity?.briefDescription}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormInputField
                  id="contactInfo"
                  label="Información de Contacto"
                  as="textarea"
                  defaultValue={entity?.contactInfo}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </EntityEditForm>
    </div>
  );
}
