import React from 'react';
import ConversationMessageBubble from './ConversationMessageBubble';
import { Conversation } from '../types/model';
import './conversation.css';

interface ConversationContainerProps {
  conversation: Conversation;
  style?: React.CSSProperties;
}

export default function ConversationContainer({ conversation, style }: ConversationContainerProps) {
  function getMessageOrConversationDate() {
    if (conversation.messages[0]) {
      return new Date(conversation.messages[0]?.timestamp);
    } else {
      return new Date(conversation.createdAt);
    }
  }

  return (
    <div className="conversation-container" style={style}>
      <p className="messages-header">
        Conversación con:&nbsp;
        <span>{conversation.messages[0]?.senderIdentifier}</span>
        &nbsp;|&nbsp;
        <span>
          {new Intl.DateTimeFormat('es-ES', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }).format(getMessageOrConversationDate())}
        </span>
      </p>
      <div className="messages-container">
        {conversation.messages?.map((message) => (
          <ConversationMessageBubble key={message.id} message={message} />
        ))}
      </div>
    </div>
  );
}
