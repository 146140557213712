import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIClient from '../services/APIClient';

import { Conversation } from '../types/model';
import ConversationContainer from './ConversationContainer';

export default function ConversationManager() {
  const [conversation, setConversation] = useState<Conversation>();
  const params = useParams();

  useEffect(() => {
    setConversation(undefined);
    APIClient.get(`/conversations/${params.id}`).then((res) => {
      setConversation(res.data.data);
    });
  }, [params.id]);

  return conversation ? (
    <ConversationContainer conversation={conversation} style={{ width: '55vw' }} />
  ) : (
    <span className="m-auto w-fit fs-3">Cargando conversación...</span>
  );
}
