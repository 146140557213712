import React from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faUndoAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { EntityEditForm, FormInputField, FormCheckField, DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { Link } from 'react-router-dom';
import { isObjectEmpty } from '../../utils';
import { formatAmount } from '../Utils';
import { WithRouterProps, withRouter } from '../withRouter';
import { AxiosResponse } from 'axios';

// these constants will indicate which load modal will be open
const MODAL_MANUAL = 'modal_manual';
const MODAL_EXCEL = 'modal_excel';

interface PriceListEditState {
  productsList: any[];
  entity: any;
  id: number;
  isAdding: boolean;
  detalles: any[];
  deletedDetalles: any[];
  isDataLoading: boolean;
  newDetalles: any[];
  selectedDetalleRowDel: any;
  selectedProducts: any[];
  showAddModal: boolean;
  showDeleteModal: boolean;
  showUploadModal: boolean;
  updatedDetalles: any[];
}

class ListaPrecioEdit extends React.Component<WithRouterProps, PriceListEditState> {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.params;

    this.state = {
      productsList: [],
      entity: {},
      id,
      isAdding: typeof id === 'undefined',
      detalles: [],
      deletedDetalles: [],
      isDataLoading: typeof id !== 'undefined',
      newDetalles: [],
      selectedDetalleRowDel: {},
      selectedProducts: [],
      showAddModal: false,
      showDeleteModal: false,
      showUploadModal: false,
      updatedDetalles: [],
    };
  }

  afterSaveNewDetalle = (row) => {
    // check if row belongs to product or ListaPrecioDetalle
    this.onSaveNewDetalle(row);
  };

  afterSaveUpdatedDetalle = (oldValue, newValue, row) => {
    this.onSaveUpdatedDetalle(row, newValue);
  };

  handleUploadModalClose = () => {
    this.setState({ showUploadModal: false });
  };

  uploadExcelModal = () => (
    <Modal size="xl" show={this.state.showUploadModal} onHide={this.handleUploadModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Carga de precios desde un documento excel</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <input name="file" type="file" accept=".xlsx" onChange={this.onReadFile} />
        <Button variant="primary" onClick={this.saveNewDetalles}>
          Listo
        </Button>
        <Button variant="secondary" onClick={this.handleUploadModalClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  onReadFile = (e) => {
    const { toastManager } = this.props;
    if (e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          if (e.target && e.target.result) {
            const data = new Uint8Array(e.target.result as ArrayBufferLike);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            (this as ListaPrecioEdit).loadFileToTable(sheet);
          }
        };

        reader.readAsArrayBuffer(file);
      } catch (error) {
        toastManager.add('Por favor verifique el archivo', {
          appearance: 'error',
        });
      }
    }
  };

  loadFileToTable = (data) => {
    const { productsList } = this.state;
    const toAddProducts = productsList.filter((e) => {
      for (let i = 1; i < data.length; i += 1) {
        if (data[i][0] === Number(e.codigoEan13)) {
          e.precio = String(data[i][3]);
          return e;
        }
      }
    });
    toAddProducts.forEach((product) => {
      this.onSelectProduct(product, true);
    });
  };

  productsListModal = () => {
    // render products modal
    const { productsList } = this.state;

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => (
          <div>
            {row.erpCodigo}
            <br />
            <small title="EAN">{row.codigoEan13}</small>
          </div>
        ),
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
        editable: false,
      },
      {
        dataField: 'productCategory2.descripcion',
        text: 'Línea',
        sort: true,
        editable: false,
      },
      {
        dataField: 'productCategory1.descripcion',
        text: 'Colección',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
        formatter: (cellContent, row) => {
          return cellContent ? (
            formatAmount(cellContent)
          ) : (
            <span className="text-danger font-weight-bold">No tiene precio.</span>
          );
        },
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      // clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectProduct,
    };

    return (
      <Modal size="xl" show={this.state.showAddModal} onHide={this.handleAddModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Productos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {productsList !== undefined && !isObjectEmpty(productsList) && (
            <DataTable
              isDataLoading={this.state.isDataLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={productsList || []}
              keyField="id"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.saveNewDetalles}>
            Listo
          </Button>
          <Button variant="secondary" onClick={this.handleAddModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  deleteDetalleModal = () => (
    <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar este detalle?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.onConfirmDelete}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={this.handleDeleteModalClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  handleAddModalClose = () => {
    // close modal
    this.setState({ showAddModal: false });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  prepareToSave = (entityToSave) => {
    /*
    const { updatedDetalles } = this.state;
    entityToSave.detalles = updatedDetalles;
    */
    const { detalles } = this.state;
    entityToSave.detalles = detalles;
    return entityToSave;
  };

  saveNewDetalles = () => {
    // copy detalles and newDetalles from state and merge them
    this.setState((prevState) => ({
      detalles: [...prevState.newDetalles, ...prevState.detalles],
      newDetalles: [],
      updatedDetalles: prevState.newDetalles,
      showAddModal: false,
      showUploadModal: false,
    }));
  };

  /**
   * @deprecated Se elimina la funcionalidad de agregar items a la lista de precios.
   */
  showAddDetalleModal = async (modal) => {
    const { detalles } = this.state;
    // get products and add precio and isSelected to all objects in array. then update state
    const detalleProductIds = detalles.map((detalle) => detalle.productId);
    const productsListRes = await APIClient.get(
      `/products?filter[id][notIn]=${detalleProductIds}&filter[eliminadoFlag][eq]=0`,
    );
    const productsList = productsListRes.data.data;
    productsList.forEach((product) => {
      product.precio = '';
      product.isSelected = false;
    });
    if (modal === MODAL_MANUAL) {
      this.setState({ showAddModal: true, isDataLoading: false, productsList });
    } else if (modal === MODAL_EXCEL) {
      this.setState({ showUploadModal: true, isDataLoading: false, productsList });
    }
  };

  onClickingDelete = (row) => {
    this.setState({ showDeleteModal: true, selectedDetalleRowDel: row });
  };

  onClickingUndo = (row) => {
    row.deletedRow = false;
    this.setState((prevState) => {
      const { detalles, deletedDetalles } = prevState;
      const newDetalles = [...detalles];
      const newDeletedDetalles = [...deletedDetalles];
      const detalleIndex = newDeletedDetalles.findIndex((det) => det.id === row.id);
      newDeletedDetalles.splice(detalleIndex, 1);
      for (let i = 0; i < newDetalles.length; i += 1) {
        const curDetalle = newDetalles[i];
        if (row.productId === curDetalle.productId) {
          newDetalles.splice(i, 1);
        }
        if (row.id < curDetalle.id) {
          newDetalles.splice(i, 0, row);
          break;
        }
      }
      return { detalles: [...newDetalles], deletedDetalles: [...newDeletedDetalles] };
    });
  };

  onConfirmDelete = () => {
    // delete detalle from detalles list and add to deletedDetalles
    this.setState((prevState) => {
      const detalles = [...prevState.detalles];
      const deletedDetalles = [...prevState.deletedDetalles];
      const selectedDetalleRowDel = { ...prevState.selectedDetalleRowDel };
      const updatedDetalles = [...prevState.updatedDetalles];

      selectedDetalleRowDel.deletedRow = true;
      deletedDetalles.push(selectedDetalleRowDel);

      const selectedIndex = detalles.findIndex(
        (detalle) => detalle.id === selectedDetalleRowDel.id,
      );
      detalles.splice(selectedIndex, 1);
      const updatedIndex = updatedDetalles.findIndex(
        (detalle) => detalle.id === selectedDetalleRowDel.id,
      );
      updatedDetalles.splice(updatedIndex, 1);

      return {
        detalles: prevState.entity.id ? [selectedDetalleRowDel, ...detalles] : detalles,
        deletedDetalles,
        selectedDetalleRowDel: {},
        showDeleteModal: false,
      };
    });
  };

  onLoadForm = async () => {
    // get marcas
    // const marcasRes = await APIClient.get('/marcas');
    // this.setState({
    // });
  };

  onRetrieveEntity = async () => {
    const { toastManager } = this.props;
    const { id } = this.state;

    try {
      // get listas precio
      const listasPrecioRes = await APIClient.get(`/listas-precio/${id}`);
      const listaPrecio = listasPrecioRes.data.data;

      listaPrecio.detalles.forEach((detalle) => {
        detalle.isSelected = false;
      });

      const detalles = [...listaPrecio.detalles];

      this.setState({
        detalles,
        entity: listaPrecio,
        isDataLoading: false,
      });
      return listaPrecio;
    } catch (error) {
      this.setState({ isDataLoading: false });
      return toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  /**
   * @deprecated Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, deletedDetalles, detalles } = this.state;
    const { navigate, toastManager } = this.props;
    try {
      // entityToSave.detalles = updatedDetalles;

      let validator = true;

      // price field validation
      detalles.map((e) => {
        if (e.precio === '') {
          validator = false;
        }
        return e;
      });

      if (!validator) {
        return toastManager.add(
          'Debe rellenar los campos de precios en la tabla de los productos',
          {
            appearance: 'warning',
            autoDismiss: true,
          },
        );
      }

      if (deletedDetalles.length > 0) {
        const deletedDetallesIds = deletedDetalles.map((detalle) => detalle.id);
        entityToSave.assocToDelete = { detalles: [...deletedDetallesIds] };
      }
      let saveResponse: AxiosResponse;
      if (isAdding) {
        entityToSave.detalles.forEach((detalle) => delete detalle.product);
        saveResponse = await APIClient.post('/listas-precio', entityToSave);
      } else {
        saveResponse = await APIClient.patch(`/listas-precio/${id}`, entityToSave);
      }

      navigate('/listas-precio');
      return toastManager.add(`Lista de Precio ${saveResponse.data.data.id} guardada con éxito`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      return toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  onSaveNewDetalle = (product) => {
    const { id } = this.state;

    this.setState((prevState) => {
      let newDetalles = [...prevState.newDetalles];

      // loking for Product in newDetalles
      let productExists = false;
      for (let i = 0; i < newDetalles.length; i += 1) {
        if (newDetalles[i].productId === product.id) {
          productExists = true;
        }
      }
      // if product exists, it's not created as newDetalle
      if (productExists === true) {
        if (product.isSelected === false) {
          newDetalles = newDetalles.filter((detalle) => detalle.product.id !== product.id);
          return { ...prevState, newDetalles };
        }

        return null;
      }
      const newDetalle = {
        product,
        productId: product.id,
        precio: product.precio.replace(',', '.'),
        listaPrecioId: id,
        talleNombre: 'ST',
        addedRow: true,
      };

      newDetalles.push(newDetalle);

      return { ...prevState, newDetalles };
    });
  };

  onSaveUpdatedDetalle = async (product, newValue) => {
    // search for existing detalles and save updates
    const { updatedDetalles } = this.state;
    const newUpdatedDetalles = [...updatedDetalles];
    const newDetalle: any = {};
    const existingDetalle = newUpdatedDetalles.find((detalle) => detalle.id === product.productId);
    if (existingDetalle === undefined) {
      newDetalle.id = product.productId;
      newDetalle.productId = product.productId;
      newDetalle.precio = product.precio;
      newUpdatedDetalles.push(newDetalle);
    } else {
      existingDetalle.precio = newValue;
    }
    this.setState({ updatedDetalles: newUpdatedDetalles });
  };

  onSelectProduct = (row, isSelect) => {
    // toggle isSelected value from product
    if (isSelect === true) {
      row.isSelected = true;
      this.afterSaveNewDetalle(row);
    } else {
      row.isSelected = false;
      this.afterSaveNewDetalle(row);
    }
  };

  render() {
    const { id, detalles, entity, isAdding, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'Product.erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'Product.descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
        formatter: (cell) => {
          return cell ? (
            formatAmount(cell)
          ) : (
            <span className="text-danger font-weight-bold">No tiene precio.</span>
          );
        },
      },
      // {
      //   dataField: 'isSelected',
      //   text: '',
      //   sort: false,
      //   align: 'center',
      //   headerAlign: 'center',
      //   editable: false,
      //   formatter: (cell, row) => {
      //     if (row.deletedRow) {
      //       return <FontAwesomeIcon icon={faUndo} onClick={() => this.onClickingUndo(row)} fixedWidth size="xs" />;
      //     }
      //     return <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.onClickingDelete(row)} fixedWidth size="xs" />;
      //   },
      // },
    ];

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: false,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const rowClasses = (row) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
      return false;
    };

    const goBack = () => {
      window.history.back();
    };

    return (
      <div>
        <h1 className="page-title">
          {isAdding ? 'Lista de Precio nueva' : `Lista de Precio #${id}`}
        </h1>
        {this.productsListModal()}
        {this.deleteDetalleModal()}
        {this.uploadExcelModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          isEditable={false}
          // onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}>
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="tipo"
                  disabled
                  label="Tipo"
                  as="input"
                  defaultValue={entity.tipo}
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="codigo"
                  disabled
                  label="Código"
                  as="input"
                  defaultValue={entity.codigo}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="descripcion"
                  disabled
                  label="Descripcion"
                  as="input"
                  defaultValue={entity.descripcion}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <FormCheckField id="eliminadoFlag" label="Inactivo" defaultChecked={entity.eliminadoFlag} />
              </Col>
            </Row> */}
            <Row>
              <Col>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.afterSaveUpdatedDetalle,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={detalles || []}
                  keyField="productId"
                  isDataLoading={isDataLoading}
                  rowClasses={rowClasses}
                  // addButton={() => this.showAddDetalleModal(MODAL_MANUAL)}
                  // onSecondButtonClick={() => this.showAddDetalleModal(MODAL_EXCEL)}
                  secondButtonText="Importar excel"
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(withRouter(ListaPrecioEdit));
