import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';
import { WithRouterProps, withRouter } from '../withRouter';
import { AxiosResponse } from 'axios';

interface BrandEditState {
  id: number;
  isAdding: boolean;
  entity: any;
}

class MarcaEdit extends React.Component<WithRouterProps, BrandEditState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: WithRouterProps) {
    super(props);

    const { id } = props.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get entity
    const entityResponse = await APIClient.get(`/marcas/${id}`);
    const entity = entityResponse.data.data;

    this.setState({ entity });
    return entity;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { navigate, toastManager } = this.props;

    let saveResponse: AxiosResponse;
    if (isAdding) {
      saveResponse = await APIClient.post('/marcas', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/marcas/${id}`, entityToSave);
    }

    toastManager.add(
      `Marca ${saveResponse.data.data.codigo} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => navigate('/marcas'),
    );
    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Marca nueva' : `Marca #${id}`}</h1>

        <EntityEditForm
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="codigo"
                  label="Código"
                  as="input"
                  defaultValue={entity.codigo}
                  required
                />
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  as="input"
                  defaultValue={entity.descripcion}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField
                  id="eliminadoFlag"
                  label="Inactivo"
                  defaultChecked={entity.eliminadoFlag}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(withRouter(MarcaEdit));
