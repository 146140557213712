import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
} from 'react-bootstrap';
import { FormSelectField, FormInputField } from '.';
import { AgentState } from '../views/Vendedor/agentInterface';
import { SourceChannel } from '../constants'
import APIClient from '../services/APIClient';
import { OrderStatus } from '../types/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faCaretRight,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications'
import FormSelectCustom, { SelectCustomOption } from './FormSelectCustom';

interface OrderFiltersProps {
  onFilterSubmit?: (filtersWithOperators: FiltersWithOperators) => void;
  handleFilterSubmitQueryString?: (filterQuerystring: string) => void;
  isDataLoading: boolean;
}

export enum Operators {
  eq = 'eq',
  like = 'like',
  gte = 'gte',
  lte = 'lte',
  is = 'is',
  isNull = 'isNull',
}

export interface FiltersWithOperators {
  client?: { [Operators.eq]: string };
  agent?: { [Operators.eq]: string };
  purchaseOrder?: { [Operators.like]: string };
  curFromDate?: { [Operators.gte]: string };
  curToDate?: { [Operators.lte]: string };
  requestStatus?: { [Operators.eq]: string };
  freeText?: { [Operators.like]: string };
  sourceChannel?: { [Operators.eq]: string };
  user?: { [Operators.eq]: string } | { [Operators.isNull]: null};
}

export interface Filters {
  client?: string;
  agent?: string;
  curFromDate?: string;
  curToDate?: string;
  requestStatus?: string;
  purchaseOrder?: string;
  freeText?: string;
  sourceChannel?: string;
  user?: string;
}

export default function OrderFilters({
  onFilterSubmit,
  handleFilterSubmitQueryString,
  isDataLoading
}: OrderFiltersProps) {
  const [filters, setFilters] = useState<Filters>({})
  const [agents, setAgents] = useState<AgentState[]>([]);
  const [orderStatus, setOrderStatus] = useState<OrderStatus[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isFilterLoadingData, setIsFilterLoadingData] = useState(false);
  const { addToast } = useToasts()

  const handleChangeValue = (e) => {
    const { value, id } = e.target;
    let filtersCopy = { ...filters };
    if (id && (value === '' || value == null)) {
      delete filtersCopy[id];
    } else {
      filtersCopy[id] = value;
    }
    setFilters(filtersCopy);
  }

  const loadData = async () => {
    setIsFilterLoadingData(true);
    try {
      const agentsRes = await APIClient.get(`/vendedores`);
      setAgents(agentsRes.data.data);
      const orderStatusRes = await APIClient.get(`/order-status`);
      setOrderStatus(orderStatusRes.data.data);
    } catch (error) {
      addToast(`Ocurrió un error inicializando los filtros: "${(error as Error).message}"`, {
        appearance: 'error',
      });
    } finally {
      setIsFilterLoadingData(false);
    }
  }

  const sourceChannelsChoices = [
    { id: SourceChannel.portalWeb, label: 'Portal Web' },
    { id: SourceChannel.backOffice, label: 'Central de Pedidos' },
    { id: SourceChannel.whatsapp, label: 'Whatsapp' },
    { id: SourceChannel.email, label: 'E-Mail' },
    { id: SourceChannel.api, label: 'API' },
  ];
  
  const selectCustomLoadClientOptionsHandler = async (query: string): Promise<SelectCustomOption[]> => {
    const res = await APIClient.get(
      `/clientes?filter[razonSocial][like]=${encodeURIComponent(`%${query}%`)}`,
    );
    const dataFetched = res.data.data;
    const optionsFormatted = dataFetched.map((data: any) => ({
      value: data.id,
      label: data.razonSocial,
    }));
    return optionsFormatted;
  };

  const selectCustomLoadUserOptionsHandler = async (query: string): Promise<SelectCustomOption[]> => {
    const res = await APIClient.get(
      `/usuarios?freeText=${encodeURIComponent(`%${query}%`)}`,
    );
    const dataFetched = res.data.data;
    const optionsFormatted = dataFetched.map((data: any) => ({
      value: data.id,
      label: `${data.lastName}, ${data.firstName}`,
    }));
    optionsFormatted.unshift({ value: '-1', label: 'OrderToB (automático)' });
    return optionsFormatted;
  };

  useEffect(() => {
    loadData();
  }, [])

  const addOperatorsToFilter = (): FiltersWithOperators => {
    let filtersObjForQueryParams: FiltersWithOperators = {}
    if (filters.client) {
      filtersObjForQueryParams.client = { eq: filters.client }
    }
    if (filters.agent) {
      filtersObjForQueryParams.agent = { eq: filters.agent };
    }
    if (filters.curFromDate) {
      filtersObjForQueryParams.curFromDate = { gte: `${filters.curFromDate}T00:00:00` }
    }
    if (filters.curToDate) {
      filtersObjForQueryParams.curToDate = { lte: `${filters.curToDate}T23:59:59` };
    }
    if (filters.purchaseOrder) {
      filtersObjForQueryParams.purchaseOrder = { like: `%${filters.purchaseOrder}%` }
    }
    if (filters.requestStatus) {
      filtersObjForQueryParams.requestStatus = { eq: filters.requestStatus }
    }
    if (filters.freeText) {
      filtersObjForQueryParams.freeText = { like: `%${filters.freeText}%` }
    }
    if (filters.sourceChannel) {
      filtersObjForQueryParams.sourceChannel = { eq: filters.sourceChannel }
    }
    if (filters.user) {
      if (filters.user === '-1') {
        filtersObjForQueryParams.user = { isNull: null }
      } else {
        filtersObjForQueryParams.user = { eq: filters.user }
      }
    }
    return filtersObjForQueryParams;
  }

  const convertToQueryParams = (filtersWithOperators: FiltersWithOperators): string => {
    let filterArrayParams: Array<string> = [];
    Object.entries(filtersWithOperators).forEach(([key, value]) => {
      const [[operator, filter]] = Object.entries(value)
      switch (key) {
        case 'client':
          filterArrayParams.push(`filter[clienteId][${operator}]=${filter}`)
          break;
        case 'agent':
          filterArrayParams.push(`filter[vendedorId][${operator}]=${filter}`)
          break;
        case 'curFromDate':
          filterArrayParams.push(`filter[fechaConfirmacion][${operator}]=${filter}`)
          break;
        case 'curToDate':
          filterArrayParams.push(`filter[fechaConfirmacion][${operator}]=${filter}`)
          break;
        case 'purchaseOrder':
          filterArrayParams.push(`filter[purchaseOrder][${operator}]=${filter}`)
          break;
        case 'requestStatus':
          filterArrayParams.push(`filter[estadoOrderCodigo][${operator}]=${filter}`)
          break;
        case 'freeText':
          filterArrayParams.push(`freeText=${filter}`)
          break;
        case 'sourceChannel':
          filterArrayParams.push(`filter[sourceChannel][${operator}]=${filter}`)
          break;
        case 'user':
          filterArrayParams.push(`filter[usuarioPerfilId][${operator}]=${filter}`)
          break;
        default:
          console.error('No key found in filtersWithOperators')
          break;
      }
    });
    return filterArrayParams.join('&');
  }

  const handleCleanClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('filterString');
    const cleanedFiltersUrl = `${window.location.pathname}`;
    window.history.pushState({ path: cleanedFiltersUrl }, '', cleanedFiltersUrl);
    setFilters({})
  }

  const handleSubmitClick = () => {
    if (isDataLoading || isFilterLoadingData) {
      return
    }
    const filtersWithOperators = addOperatorsToFilter()
    onFilterSubmit && onFilterSubmit(filtersWithOperators)
    const filterQueryString = convertToQueryParams(filtersWithOperators);
    handleFilterSubmitQueryString && handleFilterSubmitQueryString(filterQueryString)
  }

  const handleCollapseFilters = (e) => {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  }

  return (
    <Card className="my-3">
      <Card.Header className="d-flex justify-content-between">
        <Button onClick={handleCollapseFilters} className="mt-2 mb-2 bg-transparent btn btn-link">
          Filtros de búsqueda{' '}
          {isCollapsed ? (
            <FontAwesomeIcon icon={faCaretRight} fixedWidth className="ml-1" />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} fixedWidth className="ml-1" />
          )}
        </Button>
      </Card.Header>
      {!isCollapsed && (
        <>
          <Card.Body>
            <Row>
              <Col md={6}>
                <FormSelectCustom
                  id="client"
                  label="Clientes"
                  onLoadOptions={selectCustomLoadClientOptionsHandler}
                  onChange={handleChangeValue}
                  selectedOption={filters.client === undefined ? null : undefined}
                  disabled={isFilterLoadingData}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="agent"
                  label="Vendedores"
                  disabled={isFilterLoadingData}
                  value={filters.agent ?? ''}
                  choices={agents}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                  onChange={handleChangeValue}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormInputField
                  id="curFromDate"
                  type="date"
                  label="Confirmación desde"
                  disabled={isFilterLoadingData}
                  value={filters.curFromDate ?? ''}
                  onChange={handleChangeValue}
                />
              </Col>
              <Col md={3}>
                <FormInputField
                  id="curToDate"
                  type="date"
                  label="Confirmación hasta"
                  disabled={isFilterLoadingData}
                  value={filters.curToDate ?? ''}
                  min={filters.curFromDate}
                  onChange={handleChangeValue}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="requestStatus"
                  label="Estado del pedido"
                  choices={orderStatus}
                  value={filters.requestStatus ?? ''}
                  disabled={isFilterLoadingData}
                  choiceIdField="codigo"
                  choiceLabelField="descripcion"
                  onChange={handleChangeValue}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="purchaseOrder"
                  label="Orden de compra"
                  value={filters.purchaseOrder ?? ''}
                  disabled={isFilterLoadingData}
                  onChange={handleChangeValue}
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="freeText"
                  label="Texto libre"
                  value={filters.freeText ?? ''}
                  disabled={isFilterLoadingData}
                  onChange={handleChangeValue}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="sourceChannel"
                  label="Canal de origen"
                  choices={sourceChannelsChoices}
                  value={filters.sourceChannel ?? ''}
                  disabled={isFilterLoadingData}
                  choiceIdField="id"
                  choiceLabelField="label"
                  onChange={handleChangeValue}
                />
              </Col>
              <Col md={6}>
                <FormSelectCustom
                  id="user"
                  label="Usuario"
                  onLoadOptions={selectCustomLoadUserOptionsHandler}
                  onChange={handleChangeValue}
                  selectedOption={filters.user === undefined ? null : undefined}
                  disabled={isFilterLoadingData}
                  clearable={true}
                />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex flex-row justify-content-end">
              <Button
                disabled={isFilterLoadingData}
                className="d-flex py-2 mx-1 my-3"
                variant="primary"
                onClick={handleSubmitClick}>
                {!isDataLoading ? (
                  <p className="m-0">Buscar</p>
                ) : (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                )}
              </Button>
              <Button
                variant="secondary"
                className="d-flex py-2 mx-1 my-3"
                onClick={handleCleanClick}>
                Limpiar Filtros
              </Button>
            </div>
          </Card.Footer>
        </>
      )}
    </Card>
  );
}